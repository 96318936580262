
import { AdultParticipant } from '@/common/types/AdultParticipant.type';
import { apiService } from '@/services/api.service';
import { kioskModule } from '@/store/kiosk.vuex-module';
import { differenceInYears } from 'date-fns';
import { Options, Vue } from 'vue-class-component';
import YesNoInput from '@/modules/consent/components/form/yes-no-input.component.vue';
import CustomFieldInput from '@/modules/consent/components/form/custom-field-input.component.vue';
import { CustomField } from '@/common/types/client.type';
import { Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { OnClickOutside } from '@vueuse/components';
import { UserConsentOrderTimes } from '@/common/types/UserConsentOrderTimes.type';
import Checkbox from '@/common/components/form-ui/checkbox.component.vue';

@Options({
	name: 'MinorQuestionView',
	components: { YesNoInput, OnClickOutside, Checkbox },
})
export default class AdultView extends Vue {
	consenter!: AdultParticipant;
	pendingConsent: any | null = null;
	autoLoaded = false;

	addingMinors = false;

	timer: ReturnType<typeof setTimeout> | null = null;

	get c(): AdultParticipant {
		return this.consenter;
	}
	// #region GETTERS / SETTERS
	get adultsOnlyConsent(): boolean {
		return kioskModule.order?.CheckInOrderEntities?.every((a) => a.AdultsOnly) ?? false;
	}

	get partyName() {
		// return kioskModule.order ? `Check-In for ${kioskModule.order.LastName} Party` : 'Checkin';
		return kioskModule.order?.LastName ?? null;
	}
	get adultAge() {
		return kioskModule.adultAge;
	}
	get hasCheckins() {
		return !!kioskModule.order?.TotalPeopleCheckedIn;
	}

	//#endregion

	@Watch('addingMinors') addMinorsWatcher(v: boolean) {
		if (!v) kioskModule.clearMinorParticipants();
	}

	// validates email format

	created(): void {
		this.consenter = kioskModule.consenter;
	}

	editCompleted() {
		this.$router.push({ name: 'Completed' });
	}

	onNext() {
		const route = this.addingMinors ? 'MinorsCheckin' : this.$route.query.unassigned ? 'WaiverForms' : 'ActivitySelection';
		this.$router.push({ name: route, query: { ...this.$route.query } });
	}
}
